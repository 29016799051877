<template>
    <div v-if="check" ref="container" class="audit-content scrollbar right" :class="[$mq]">
        <div class="top-container" :class="{ 'opened-info': infoIsOpen }">
            <AppccTopDetail :auditScore="template.config" :hasMoreOptions="true" @infoOpened="infoOpened" @deleteButton="deleteAudit" @settingsButton="settingsRegister" @backButton="back" :audit="check" :userInfo="check.employee" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :isAudit="true" :sections="sections"></Template-sections>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': Object.keys(check.sections).length > 1 }, { 'sidebar-collapsed': collapsedInfo }, { 'opened-info': infoIsOpen }, 'hide-scrollbar']">
            <div v-if="collapsedInfo" class="list-info">
                <span class="action delete" @click="deleteAudit()">{{ $t('audit.actions.delete') }}</span>
            </div>

            <div class="exam performing" id="exam" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, { scrollbar: ['landscape', 'desktop'].includes($mq) }]">
                <div class="section minimize">
                    <span v-if="Object.values(closedSections).filter((s) => s).length != Object.keys(check.sections).length" class="section-title" @click.self="toggleAllSections('collapse')">{{ $t('audit.actions.collapse_all') }}</span>
                    <span v-else class="section-title expand" @click.self="toggleAllSections('expand')">{{ $t('audit.actions.expand_all') }}</span>
                </div>
                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <div v-if="check.recover && editable && !check.complete_date" class="get-last-values">
                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                </div>

                <template v-if="check">
                    <div v-if="check.sections" v-for="section in check.sections" class="section" :class="{ closed: closedSections[section.section_id] }" :id="section.section_id">
                        <span class="section-title" :openThisSection="openThisSection(section.section_id)" @click.self="toggleSection(section.section_id)" :class="[{ 'expand-full': !((template.config && template.config.show_score == undefined) || (template.config && template.config.show_score == 1 && valueInPercentage(section.completed, section.total_score) !== 0)) }]">{{ section.name }}</span>
                        <span class="section-score" :class="scoreClasses(section.score)" v-if="(template.config && template.config.show_score == undefined) || (template.config && template.config.show_score == 1 && valueInPercentage(section.completed, section.total_score) !== 0)">
                            <!-- <span class="points">{{ section.completed }}</span>
                            <span class="total">/{{ section.total_score }}</span> -->
                            <span class="percentage" v-if="valueInPercentage(section.completed, section.total_score) !== 0">{{ ' ' + valueInPercentage(section.completed, section.total_score) }}</span>
                        </span>
                        <div class="section-content">
                            <template v-show="!closedSections[section.section_id]">
                                <answer v-for="answer in section.items" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type" :key="answer.item_id" :showErrors="showErrors"></answer>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="audit-content hide-scrollbar right empty">
        <!-- <div class="message empty">{{ getString('audit.empty') }}</div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_audits')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_audit')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage.vue'
import answer from '@/components/domain/tasks/answer'
import UserTag from '@/components/layout/UserTag'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import TemplateSections from '@/components/layout/TemplateSections'
export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, TemplateSections },
    props: {
        gId: { type: String },
        showErrors: {
            type: Boolean
        },
        permissions: {
            type: Boolean
        },
        openSection: {
            type: Boolean
        }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 2,
            firstTime: true,
            closedSections: {},
            view_more: false,
            infoIsOpen: false,
            showNonApply: true
        }
    },
    computed: {
        check() {
            var check = this.$store.getters['audit/getById'](this.gId)
            if (check) {
                // check.employee.avatar = css_image_url + 'avatar/' + check.employee.avatar
                if (this.firstTime) {
                    this.firstTime = false
                    for (var k in check.sections) {
                        var section = { ...check.sections[k] }
                        this.closedSections[section.section_id] = false
                    }
                }
            }
            if (check == undefined) {
                this.$bar.hide()
            }
            return check
        },
        template() {
            // log('audtis templates -> ', this.$store.getters['audit/getTemplates'])
            var templates = this.$store.getters['audit/getTemplateById'](this.$route.params.id)
            // var showScore = { show_score: 0 }
            // templates.config = showScore

            return templates
        },
        user() {
            var userID = this.$store.getters['loginUser/getLocalEmployee']
            if (userID) {
                return this.$store.getters['employee/getEmployee'](userID)
            }
        },
        sections() {
            var sections = []
            if (this.check) {
                for (var i in this.check.sections) {
                    if (this.check.sections[i].name) {
                        sections.push(this.check.sections[i])
                    }
                }
            }
            return sections
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        }
    },
    methods: {
        valueInPercentage(value, total) {
            var result = Math.round((value / total) * 100)
            if (isNaN(result)) {
                return 0
            }

            return result + '%'
        },
        settingsRegister() {
            const self = this
            this.$popup.itemSettings({
                itemSettings: {
                    noApply: {
                        label: self.$t('popup.item_settings.no_apply'),
                        value: self.showNonApply
                    }
                },
                callSave: (settings) => {
                    const itemSettings = settings.itemSettings
                    self.showNonApply = itemSettings.noApply.value
                    self.$popup.close()
                }
            })
        },
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$emit('update-show-errors', !this.showErrors)
            this.$emit('updateToggle', !this.openSection)
            this.$router.push({
                name: 'AuditDetail',
                params: { id: this.$route.params.id }
            })
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },

        recoverValues() {
            var self = this
            self.$overlay.loading()

            self.$store.dispatch('audit/recoverValues', { check: self.gId }).then(function (response) {
                self.$overlay.hide()
                self.$snackbar.info({ message: self.$t('tooltip.done') })
            })
        },

        scoreClasses(value) {
            return {
                success: value == 100,
                progress: value > 75 && value < 100,
                warning: value > 0 && value <= 75,
                error: value == 0
            }
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        toggleSection(sec_id) {
            this.closedSections = {
                ...this.closedSections,
                [sec_id]: !this.closedSections[sec_id]
            }

            // this.$emit('updateToggle', !this.openSection)
        },
        openThisSection(sec_id) {
            if (this.openSection === true && this.closedSections[sec_id] !== false) {
                this.closedSections = {
                    ...this.closedSections,
                    [sec_id]: false
                }
            }
        },

        toggleInfo() {
            this.collapsedInfo = !this.collapsedInfo
        },

        toggleAllSections(mode) {
            this.closedSections = Object.keys(this.closedSections).reduce((acc, key) => {
                acc[key] = mode == 'expand' ? false : true
                return acc
            }, {})
        },

        rolesWithWritePermissions() {
            const permissions = this.$store.getters['audit/getById'](this.gId)
            log('zzz log', permissions)
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },

        deleteAudit() {
            if (!this.permissions) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role_permission', {
                        name: this.user.name,
                        roles: this.rolesWithWritePermissions()
                    })
                })
                return
            }

            if (!fnCheckConnection()) {
                this.$popup.alert({
                    message: this.$t('popup.audit.offline_remove')
                })
                return
            }

            var self = this
            var employee = self.currentEmployee()

            if (!self.$store.getters['hasAccess'](employee.id, 1)) {
                self.$popup.access({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response.status) {
                            self.$popup.close()
                            self.popupDeleteAudit(response.emp_id)
                        }
                    }
                })
            } else {
                self.popupDeleteAudit(employee.id)
            }
        },
        popupDeleteAudit(emp_id) {
            var self = this
            var relItem = self.refTask ? self.refTask.task_id : false
            // Validate user rol is in template audit
            var template = self.$store.getters['audit/getTemplateById'](self.$route.params.id)
            var rolEmployee = self.$store.getters['employee/getRol'](this.user.rol)
            var auditTemplateHasRoleUser = false

            var templateRoles = template.config.roles
            if (typeof templateRoles === 'undefined' || templateRoles.length == 0 || templateRoles.includes(rolEmployee.id)) {
                auditTemplateHasRoleUser = true
            }

            if (auditTemplateHasRoleUser) {
                self.$popup.confirm({
                    message: self.$t('popup.delete.title'),
                    textSave: self.$t('popup.delete.text_save'),
                    textCancel: self.$t('popup.delete.text_cancel'),
                    callSave: function () {
                        self.$overlay.loading()
                        self.$store
                            .dispatch('audit/delete', {
                                check: self.gId,
                                emp: emp_id,
                                ref_item: relItem,
                                template: self.check.template_id
                            })
                            .then(function (response) {
                                if (response) {
                                    self.$popup.close()
                                    self.$overlay.hide()
                                    self.$snackbar.success({ duration: 5000, closeable: true, message: self.$t('popup.audits.removed_successfully') })

                                    self.$router.push({
                                        name: 'AuditDetail',
                                        params: { id: self.check.template_id }
                                    })
                                }
                            })
                    }
                })
            } else {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.audits.role_not_allowed'),
                    textSave: self.$t('popup.audit.error.alert_save'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
            }
        },

        toSection(id) {
            var scroll = {
                container: this.$refs.container,
                target: document.getElementById(id),
                offset: this.check.sections.length > 0 ? -165 : -105
            }

            this.$root.scrollTo(scroll)
        },

        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        employee(id) {
            return this.$store.getters['employee/getEmployee'](id)
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    mounted() {},
    created() {
        if (localStorage.getItem('showNonApply') != undefined) {
            this.showNonApply = localStorage.getItem('showNonApply') == 'true' ? true : false
        }
    },
    watch: {
        showNonApply(newValue, oldValue) {
            if (newValue !== oldValue) {
                localStorage.setItem('showNonApply', newValue)
                this.$store.commit('checklist/setShowNoApply', newValue)
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit.detail {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;
    height: min-content;

    > .audit-content {
        $top-info: 80px;
        $resume-width: rem(230px);
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: 100%;
        overflow: hidden;
        padding: 8px;

        .detail-box {
            $sidebar-size: 40px;
            $collapse-button: 40px;
            $content-padding: 0px;
            $detail-bg: $default-bg-color;
            $detail-text-color: $main-dark;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            width: 100%;
            height: calc(100% - 120px);
            overflow: auto;
            background-color: $detail-bg;

            &.with-sections {
            }

            .list-info {
                $action-size: rem(60px);
                @include default-box-shadow();
                @include display-flex();
                @include justify-content(flex-end);
                @include align-items();
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                background-color: shade($default-bg-color, 5%);
                position: fixed;
                z-index: 9;
                width: calc(100% - #{$column-max-width} - #{$menu-tools-min} - #{$content-padding} - #{$scroll-width});
                height: $action-size;

                .action {
                    @include interaction();
                    @include font-size(xs);
                    font-family: $text-bold;
                    color: #444;
                    text-align: center;
                    display: block;
                    background-color: shade($default-bg-color, 7%);
                    width: $action-size;
                    height: $action-size;
                    padding: ($action-size - rem(20px)) 6px 2px 6px;
                    margin: 0 9px;

                    @include bgHover(shade($default-bg-color, 7%));

                    &.delete {
                        @include background($image: img('delete_alert.svg'), $size: 24px, $position: top 9px center);
                    }
                }
            }

            .exam {
                @include border-radius($default-border-radius);
                padding: 12px 0;
                height: 100%;

                .minimize {
                    opacity: 0.85;
                    margin-bottom: 0px;
                    margin-top: 0px;

                    &:hover {
                        opacity: 1;
                    }

                    .section-title {
                        @include font-size(s);

                        &.expand:before {
                            background-image: img('more_primary.svg');
                        }
                    }
                }

                .expand:before {
                    background-image: img('more_primary.svg');
                }

                .get-last-values {
                    text-align: center;

                    .message {
                        @include interaction();
                        @include border-radius($default-border-radius);
                        @include background($color: $main-t80, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                        @include font-size(m);
                        font-family: $text;
                        color: $main-dark;
                        padding: 12px 42px 12px 12px;
                        display: inline-block;

                        @include bgHover($main-t80);
                    }
                }

                .section {
                    $section-score-width: 80px;

                    padding: 6px;
                    width: 94%;
                    margin: 12px auto;

                    .section-title {
                        @include font-size(s);
                        font-family: $text;
                        color: $main-dark;
                        display: inline-block;
                        width: calc(100% - #{$section-score-width});
                        margin-bottom: 4px;

                        &.expand-full {
                            width: 100%;
                        }

                        &:before {
                            @include background($color: $main-t80, $image: img('less_primary.svg'), $size: 10px);
                            @include border-radius(3px);
                            content: '';
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            margin-right: 6px;
                            margin-bottom: -2px;
                        }
                    }
                    .section-score {
                        display: inline-block;
                        width: $section-score-width;
                        text-align: right;

                        .points {
                            @include font-size(l);
                            font-family: $conden-bold;
                            color: $main;
                        }
                        .total {
                            @include font-size(sm);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                            padding-right: 6px;
                        }
                        .percentage {
                            font-family: $text-medium;
                            color: $main;
                            background-color: #fff;
                            padding: 6px;
                            border-radius: 4px;
                            @include font-size(xs);
                            position: relative;
                            top: -2px;
                        }
                    }

                    .section-content {
                        @include display-flex();
                        @include flex-direction(row);
                        @include flex-wrap(wrap);
                        @include border-radius($default-border-radius);

                        .question-score {
                            @include display-inline-flex();
                            // @include justify-content();
                            @include align-items();
                            @include font-size(m);
                            margin: 20px auto;
                            font-family: $text;
                            color: $main;
                            width: 80px;
                            height: 50px;
                        }
                    }

                    .section-total {
                        width: 100%;
                        text-align: right;

                        .text {
                            @include font-size(sm);
                            font-family: $text-bold;
                            color: $main-dark;
                        }
                        .total {
                            @include styScore();
                            @include font-size(ml);
                            font-family: $text-bold;
                            color: $main;
                            text-align: center;
                            display: inline-block;
                            width: 80px;
                            padding: 6px;
                        }
                    }

                    &.closed {
                        //height: 50px;
                        margin: 12px auto;

                        .section-title:before {
                            background-image: img('more_primary.svg');
                        }

                        .section-content {
                            visibility: hidden;
                            display: none;
                            height: 0;
                            opacity: 0;

                            // .question, .question-score {
                            //   display: none;
                            // }
                        }
                    }
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('file_attach_aaaaaa.svg'), $position: left 12px center, $size: 35px);
            padding: 16px 20px 16px 56px;
            margin: 0 6px 0 12px;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                // line-height: 20px;
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                min-height: 20px;
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            height: auto;
            padding-bottom: 10px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding-bottom: 10px;
                max-height: 150px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 160px);
                &.with-sections {
                    height: calc(100% - 200px);

                    &.opened-info {
                        height: calc(100% - 255px);
                    }
                }

                &.opened-info {
                    height: calc(100% - 195px);
                }

                .section {
                    .section-title {
                        @include font-size(ml);
                    }
                }
                .section-score {
                    .percentage {
                        @include font-size(m, $important: true);
                    }
                }
            }
        }
        &.landscape {
            .top-container {
                padding-left: 11px;
                padding-right: 11px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 140px);

                &.with-sections {
                    height: calc(100% - 200px);

                    &.opened-info {
                        height: calc(100% - 245px);
                    }
                }

                &.opened-info {
                    height: calc(100% - 175px);
                }
                .section {
                    .section-title {
                        @include font-size(ml);
                    }
                }
                .section-score {
                    .percentage {
                        @include font-size(m, $important: true);
                    }
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;
        width: $column-width !important;
        max-width: $column-max-width !important;
    }

    // TODO REVIEW
    .question.child {
        // margin: rem(15px) auto;

        &:nth-of-type(2) {
            //   width: 85% !important;
            //   margin-left: 9%;
        }
    }

    .question {
        margin: rem(15px) auto;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.audit {
    &.detail {
        .audit-content.portrait {
            height: 100%;

            .detail-box {
                // padding-top: 60px !important;
                background: transparent;
                height: calc(100% - 175px);

                &.opened-info {
                    height: calc(100% - 220px);
                }

                .message {
                    padding-left: 10px;
                }

                .exam {
                    .section {
                        width: 100%;

                        $section-score-width: 80px;
                        margin: 8px auto;

                        .section-title {
                            width: calc(100% - #{$section-score-width});
                            @include font-size(xs);
                            line-height: normal;
                            &.expand-full {
                                width: 100%;
                            }
                        }

                        .section-score {
                            width: $section-score-width;
                        }
                    }
                    .justify-description,
                    .check-available {
                        padding: 8px 20px 8px 50px;
                        background-size: 25px;
                        background-position: left 10px center;

                        .message {
                            @include font-size(xs);
                            line-height: 1rem;
                            width: calc(100% - 100px);
                        }
                        .action {
                            width: 100px;
                            @include font-size(xs);
                            line-height: 0.75rem;
                        }
                    }
                    .check-available {
                        .message {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                    .check-description {
                        padding: 8px 20px 8px 34px;
                        background-size: 25px;
                        background-position: left 4px center;
                        margin: 0px !important;

                        .message {
                            @include font-size(xs);
                            line-height: 1rem;
                        }
                    }

                    .get-last-values {
                        .message {
                            @include font-size(xs);
                            line-height: normal;
                        }
                    }
                }
                &.not-saved {
                    height: calc(100% - 115px);
                    padding-bottom: 0px;
                }
                &.opened-info {
                    height: calc(100% - 115px);
                    padding-bottom: 0px;
                }
                &.with-sections {
                    height: calc(100% - 205px);
                    padding-bottom: 0px;

                    &.opened-info {
                        height: calc(100% - 255px);
                    }
                }
            }
            .top-container {
                height: auto;
                max-height: 120px;

                &.opened-info {
                    max-height: 170px;
                }
            }
        }
    }
}
</style>
