<template>
    <div class="template-sections-container" :class="[$mq]">
        <div v-if="sections.length > 0" class="sidebar-sections hide-scrollbar">
            <div class="control-overflow" v-if="!isAudit">
                <p v-for="(section, index) in sections" class="item" :data-id="section.item_id" :class="[{ subtitle: section.type == 9 }, { selected: currentSection == section.item_id }]" :key="index" @click="toSection(section.item_id)" v-if="section.type != 9">
                    {{ section.title }}
                </p>
            </div>
            <div class="control-overflow" v-else>
                <p v-for="(section, index) in sections" class="item" :data-id="section.section_id" :class="[{ subtitle: section.type == 9 }, { selected: currentSection == section.section_id }]" :key="index" @click="toSection(section.section_id)">
                    {{ section.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateSections',
    props: {
        sections: {
            type: undefined,
            default: false
        },
        isAudit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentSection: false
        }
    },
    methods: {
        toSection(id) {
            // get element by id
            const section = document.getElementById(id)
            section.scrollIntoView({ behavior: 'smooth' })
            this.currentSection = id
        },
        selectCategoriesWhileScrolling() {
            var self = this
            const callback = (entries, observer) =>
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // compare parentElement.id if is in section.item_id

                        if (!self.isAudit) {
                            if (self.sections.find((section) => section.item_id == entry.target.id)) {
                                self.currentSection = entry.target.id
                                // do scroll to horizontal sections
                                if (entry.target.id) {
                                    // this is a div horizontal
                                    const section = document.querySelectorAll(`[data-id="${entry.target.id}"]`)[0]
                                    section.scrollIntoView(true, { behavior: 'smooth' })
                                }
                            }
                        } else {
                            if (self.sections.find((section) => section.section_id == entry.target.id)) {
                                self.currentSection = entry.target.id
                                // do scroll to horizontal sections
                                if (entry.target.id) {
                                    // this is a div horizontal
                                    const section = document.querySelectorAll(`[data-id="${entry.target.id}"]`)[0]
                                    section.scrollIntoView(true, { behavior: 'smooth' })
                                }
                            }
                        }
                        observer.unobserve(entry.target)
                        observer.disconnect()
                    }
                })
            const observer = new IntersectionObserver(callback, {
                rootMargin: '0px',
                root: document.querySelector('#exam'),
                threshold: 0.6
            })
            if (!self.isAudit) {
                document.querySelectorAll('.question').forEach((item) => observer.observe(item))
            } else {
                document.querySelectorAll('.section').forEach((item) => observer.observe(item))
            }
        },

        // CUANDO HACES SCROLL TE SELECCIONE LA CATEGORÍA QUE VES
        handleScroll() {
            var self = this
            document.querySelector('#exam').addEventListener('touchmove', function (event) {
                self.selectCategoriesWhileScrolling()
            })
            document.querySelector('#exam').addEventListener('wheel', function (event) {
                // only when scroll down or up
                if (event.deltaY > 0 || event.deltaY < 0) self.selectCategoriesWhileScrolling()
            })
        }
    },
    mounted() {
        this.handleScroll()
        if (!this.isAudit) {
            this.currentSection = this.sections[0].item_id
        } else {
            this.currentSection = this.sections[0].section_id
        }
    },
    beforeDestroy() {
        window.removeEventListener('wheel', {})
        window.removeEventListener('touchmove', {})
    }
}
</script>

<style lang="scss" scoped>
.template-sections-container {
    @include display-flex();
    @include align-items();
    width: 100%;
    height: 100%;
    padding: 12px;
    .sidebar-sections {
        @include border-radius(4px);
        height: 40px;
        position: relative;
        background-color: #fff;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;

        .control-overflow {
            @include display-flex();
            @include align-items(center);
            @include justify-content(flex-start);
            @include flex-direction(row);
            @include width-max-content();
            min-width: 100%;
            padding: 3px;
            height: 40px;
        }

        .item {
            @include interaction();
            @include width-fit-content();
            @include text-ellipsis();
            @include font-size(sm);
            @include display-flex();
            @include align-items(center);
            height: 36px !important;
            font-family: $text;
            color: $neutro-s50;
            padding: 0 12px;
            cursor: pinter;
            // transition color and background-color and font size
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, font-size 0.3s ease-in-out;

            &.selected {
                font-family: $text-bold;
                @include font-size(sm);
                @include border-radius(4px);
                background-color: $main-t80;
                color: $main-dark;
            }
        }
    }
    &.portrait {
        padding: unset;
        padding-top: 6px;

        .item {
            @include font-size(xs);

            &.selected {
                @include font-size(xs);
            }
        }
    }
}
</style>
